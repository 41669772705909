* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --medium-purple: #8F7CEC;
    --raisin-black: #242026;
    --pastel-red: #FC6767;
    --quick-silver: #A1A1A1;
    --cyber-yellow: #FFD000;
}

.sign-up-container {
    display: grid;
    place-items: center;
    height: calc(100vh - 80px);
}

.form {
    position: relative;
    max-width: 40rem;
    max-height: 70vh;
    padding: 8rem 0;
    color: white;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.1);
    isolation: isolate;
}

.form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 40vh;
    background: linear-gradient(to left, var(--raisin-black), var(--medium-purple));
    border-radius: 1rem 1rem 100% 100%;
    z-index: -1;
}

.form ul {
    margin-top: 10px;
    margin-bottom: -35px;
    color: var(--raisin-black);
}

.form li {
    margin-bottom: 5px;
}

.form-title {
    position: absolute;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
}

.form > form {
    padding: 2.5rem;
    background-color: inherit;
}

.form-input {
    position: relative;
    display: flex;
    align-items: baseline;
    margin: 0 auto;
}

.form-input > i {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--pastel-red);
}

.form-input input {
    position: relative;
    width: 100%;
    font: inherit;
    padding: 1rem 0;
    margin-top: 0.5rem;
    border: none;
    outline: none;
}

.form-input .bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 1px;
}

.form-input .bar::before {
    content: "";
    position: absolute;
    background-color: var(--medium-purple);
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
}

.form-input > input:focus + .bar::before {
    transform: scaleX(1);
}

.password-strength-label {
    font-size: 16px;
    margin-right: 5px;
    margin-left: 15px;
    color: var(--raisin-black);
    opacity: 0.6;
}

.password-strength-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    z-index: 10;
    background-color: white;
}

.password-strength {
    display: block;
    height: 8.5px;
    width: 100%;
    border-radius: 10px;
    border-style: solid;
    border-color: var(--raisin-black);
}

.form-input .password-strength span {
    display: block;
    
}

.weak,
.average,
.strong {
    height: 5px;
    border-radius: 10px;
}

.weak {
    width: 33%;
    background-color: red;
    color: red;
}

.average {
    width: 66%;
    background-color: var(--cyber-yellow);
    color: var(--cyber-yellow);
}

.strong {
    width: 100%;
    background-color: green;
    color: green;
}

.form-button {
    display: block;
    margin: 2.5rem auto 3rem;
    padding: 1rem 5rem;
    background: linear-gradient(to left, var(--raisin-black), var(--medium-purple));
    color: white;
    font: inherit;
    border: 0.5rem solid white;
    outline: none;
    cursor: pointer;
    border-radius: 3rem;
}

.form-button:hover {
    background: linear-gradient(to right, var(--raisin-black), var(--medium-purple));
}

.form-switch {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    color: var(--quick-silver);
}

.form-switch .link {
    text-decoration: none;
    color: var(--medium-purple);
    margin-left: 0.5rem;
}

.form-switch .link:hover {
    text-decoration: underline;
}

@media screen and (min-width: 960px) {
    .form::before {
        height: 30rem;
    }

    .form-title {
        left: 10rem;
        transform: none;
    }

    .form > form {
        transform: translateX(10rem);
        border-radius: inherit;
        box-shadow: inherit;
    }
}

@media screen and (max-width: 959px) {
    .form {
        max-height: 40rem;
        padding: 4rem 0;
    }

    .form > form {
        border-radius: inherit;
        max-height: 30rem;
    }

    .form-button {
        margin: 2.25rem auto -0.5rem auto;
    }

    .form-title {
        color: var(--medium-purple);
    }

    .password-strength {
        height: 8px;
    }
}

@media screen and (max-width: 425px) {
    li {
        margin-bottom: 5px;
    }

    .sign-up-container {
        place-items: center;
        justify-content: center;
    }

    .form {
        max-width: 20rem;
        padding: 3rem 0;
        height: 100%;
    }

    .form > form {
        max-height: 39rem;
    }

    .form-button {
        margin: 2.5rem auto -0.25rem auto;
    }
}