* {
    box-sizing: border-box;
}

:root {
    --raisin-black: #242026;
    --medium-purple: #8F7CEC;
    --thistle: #CBBDDB;
}

body {
    background: black;
}

p.register {
    margin: -30px 0 40px 0;
    width: 100%;
}

.login-screen {
    width: 100vw;
    height: calc(100vh - 80px);
    background-color: black;
}

.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: var(--raisin-black);
    box-shadow: 0 15px 25px rgba(143, 124, 236, 0.7);
    border-radius: 10px;
}

.login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: white;
    text-align: center;
}

.login-box .user-box input {
    position: relative;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: white;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    background: transparent;
}

.login-box .user-box label {
    position: relative;
    left: 0;
    top: -60px;
    padding: 10px 0;
    font-size: 16px;
    color: white;
    pointer-events: none;
    transition: all 0.5s;
}

.login-box .user-box input:focus + label,
.login-box .user-box input:not(:placeholder-shown) + label {
    top: -85px;
    left: 0;
    color: var(--medium-purple);
    font-size: 12px;
}

.submit {
    padding: 10px 20px;
    background-color: var(--raisin-black);
    color: var(--thistle);
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    letter-spacing: 4px;
    border: 1px solid var(--medium-purple);
    margin: auto;
}

.submit:hover {
    background: var(--medium-purple);
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px var(--medium-purple), 0 0 50px var(--medium-purple), 0 0 100px var(--medium-purple);
    cursor: pointer;
}

.button-form {
    display: flex;
    flex-direction: row;
    margin-top: -20px;
}

.register {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 14px;
    text-decoration: none;
    color: var(--thistle);
    margin: auto;
    width: 60%;
    text-align: center;
}

.register-forgot-password-container {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.sign-up,
.forgot-password {
    margin: auto;
    color: var(--medium-purple);
    text-decoration: none;
}

.forgot-password {
    background-color: var(--raisin-black);
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .login-box {
        width: 325px;
    }
}