:root {
    --raisin-black: #242026;
}

.bl-screen {
    display: flex;
    flex-direction: row;
    background-color: white;
    min-height: calc(100vh - 80px);
    height: 100%;
    width: 100vw;
}

.bl-tasks,
.bl-in-progress,
.bl-done {
    display: flex;
    flex-direction: column;
    width: 33.35%;
    border: 2px solid;
    align-items: center;
    padding-top: 1.5rem;
    overflow-y: auto;
    
}

.new-task-input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
}

.new-task-input {
    width: 80%;
    height: 40px;
    border-width: 4px;
    padding-left: 0.25rem;
    margin-right: 0.25rem;
}

.btn-create-task {
    background-color: transparent;
    color: var(--raisin-black);
    padding: 8px 20px;
    margin: 0 5px;
    border: 2px solid var(--raisin-black);
    transition: all 0.3s ease-out;
    font-size: 20px;
}

.btn-create-task:hover {
    background: var(--raisin-black);
    color: white;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.empty-task-list {
    margin: auto;
    font-size: 60px;
    opacity: 0.5;
    text-align: center;
}

.divider {
    width: 100%;
    min-height: 40px;
    margin: 0.5rem;
}

.page-selector-container {
    display: flex;
    justify-content: center;
    gap: 2.5rem;
}

.page-selector-active,
.page-selector {
    margin-top: 40px;
    width: 200px;
}

.page-selector-active {
    color: white;
    background-color: var(--raisin-black);
}

@media screen and (max-width: 960px) {
    .bl-screen {
        flex-direction: column;
        height: calc(100vh - 80px);
    }

    .bl-tasks,
    .bl-in-progress,
    .bl-done {
        height: 100%;
        width: 100%;
        border: none;
    }
}

@media screen and (max-width: 425px) {
    .page-selector-active,
    .page-selector {
        width: 100px;
        font-size: 12px;
    }

    .new-task-input {
        margin: 0 0.25rem 0 0.5rem;
    }

    .empty-task-list {
        font-size: 36px;
    }
}