:root {
    --raisin-black: #242026;
}

.task-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-height: 40px;
    align-items: center;
}

.task-item-container {
    width: 100%;
    border-style: solid;
    border-color: var(--raisin-black);
    border-width: 0 0 3px 0;
    padding: 1rem 2.5rem 1rem 2rem;
}

.tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.add-tag {
    display: flex;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
}

.add-tag:hover {
    cursor: pointer;
}

.task-not-resolved {
    text-decoration: none;
}

.task-resolved {
    text-decoration: line-through;
}

.dropdown-menu,
.btn-remove-task {
    background-color: transparent;
    color: var(--raisin-black);
    padding: 2px 8px;
    margin: 0 5px;
    border: 2px solid var(--raisin-black);
    transition: all 0.3s ease-out;
    align-self: center;
}

.dropdown-menu {
    padding: 2px 0;
    margin-left: auto;
}

.dropdown-menu:hover,
.btn-remove-task:hover {
    background: var(--raisin-black);
    color: white;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .task-item {
        padding: 1rem 0.25rem 1rem 1.5rem;
    }
}