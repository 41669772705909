:root {
    --raisin-black: #242026;
    --medium-purple: #8F7CEC;
}

.fp-screen {
    width: 100vw;
    height: calc(100vh - 80px);
}

.fp-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: var(--raisin-black);
    box-shadow: 0 15px 25px rgba(143, 124, 236, 0.7);
    border-radius: 10px;
}

.fp-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: white;
    text-align: center;
}

.fp-box .fp-email-box input {
    position: relative;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: white;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    background: transparent;
}

.fp-box .fp-email-box label {
    position: relative;
    left: 0;
    top: -60px;
    padding: 10px 0;
    font-size: 16px;
    color: white;
    pointer-events: none;
    transition: all 0.5s;
}

.fp-box .fp-email-box input:focus + label,
.fp-box .fp-email-box input:not(:placeholder-shown) + label {
    top: -85px;
    left: 0;
    color: var(--medium-purple);
    font-size: 12px;
}

@media screen and (max-width: 425px) {
    .fp-box {
        width: 325px;
    }
}