:root {
    --primary: #ffffff;
    --raisin-black: #242424;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--outline {
    background-color: transparent;
    padding: 8px 20px;
    margin: 0 5px;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
    transition: all 0.3s ease-out;
}

@media (prefers-color-scheme: light) {
    .btn--primary {
        background-color: var(--raisin-black);
        color: white;
        border: 1px solid var(--raisin-black);
    }

    .btn--outline {
        color: var(--raisin-black);
        border: 1px solid var(--raisin-black);
    }

    .btn--medium:hover,
    .btn--large:hover {
        background: var(--raisin-black);
        color: white;
    }
}

@media (prefers-color-scheme: dark) {
    .btn--primary {
        background-color: white;
        color: var(--raisin-black);
        border: 1px solid white;
    }

    .btn--outline {
        color: white;
        border: 1px solid white;
    }

    .btn--medium:hover,
    .btn--large:hover {
        background: white;
        color: var(--raisin-black);
    }
}