:root {
    --medium-purple: #8F7CEC;
    --raisin-black: #242026;
}

.settings-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    align-items: center;
    color: white;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 700px;
    padding: 40px;
    background: var(--raisin-black);
    box-shadow: 0 15px 25px rgba(143, 124, 236, 0.7);
    border-radius: 10px;
    gap: 1rem;
    margin-top: 5rem;
}

.info-container p {
    font-size: 26px;
    margin-left: 5px;
}

.theme-container,
.name-container,
.email-container,
.password-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.theme-info,
.name-info,
.email-info,
.password-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border-bottom: white solid;
}



.theme-info form {
    font-size: 26px;
}

.theme-info form label {
    margin-left: 1rem;
}

.btn-edit {
    background-color: transparent;
    color: white;
    padding: 4px 20px;
    margin-left: auto;
    border: 2px solid white;
    transition: all 0.3s ease-out;
    align-self: center;
    font-size: 20px;
    text-decoration: none;
}

.btn-edit:hover {
    background: white;
    color: var(--raisin-black);
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.email-container .edit-email-box input,
.name-container .edit-name-box input {
    position: relative;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 15px;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    background: transparent;
}

.email-container .edit-email-box label,
.name-container .edit-name-box label {
    position: relative;
    left: 0;
    top: -30px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    pointer-events: none;
    transition: all 0.5s;
}

.email-container .edit-email-box input:focus + label,
.email-container .edit-email-box input:not(:placeholder-shown) + label,
.name-container .edit-name-box input:focus + label,
.name-container .edit-name-box input:not(:placeholder-shown) + label {
    top: -50px;
    left: 0;
    color: var(--medium-purple);
    font-size: 12px;
}

@media screen and (max-width: 960px) {
    h1 {
        font-size: 26px;
    }
    .info-container {
        width: 500px;
        padding: 20px;
    }
    
    .info-container p {
        font-size: 20px;
    }
}

@media screen and (max-width: 425px) {
    h1 {
        font-size: 15px;
    }
    .info-container {
        width: 300px;
        padding: 20px 10px;
    }
    
    .info-container p {
        font-size: 12px;
    }

    .btn-edit {
        background-color: transparent;
        color: white;
        padding: 4px 12px;
        margin-left: auto;
        border: 2px solid white;
        transition: all 0.3s ease-out;
        align-self: center;
        font-size: 12px;
        text-decoration: none;
    }

    .email-container .edit-email-box input,
    .name-container .edit-name-box input,
    .email-container .edit-email-box label,
    .name-container .edit-name-box label {
        font-size: 15px;
    }
}