:root {
    --raisin-black: #242424;
}

.navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.navbar-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: start;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
}

.nav-links:hover {
    transition: all 0.2s ease-out;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media (prefers-color-scheme: light) {
    .navbar {
        background: white;
    }

	.navbar-logo {
        color: var(--raisin-black);
    }

    .nav-links {
        color: var(--raisin-black);
    }

    .nav-links:hover {
        border-bottom: 4px solid var(--raisin-black);
    }

    .fa-bars {
        color: var(--raisin-black);
    }
}

@media (prefers-color-scheme: dark) {
    .navbar {
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    }

	.navbar-logo {
        color: white;
    }

    .nav-links {
        color: white;
    }

    .nav-links:hover {
        border-bottom: 4px solid white;
    }

    .fa-bars {
        color: white;
    }
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: var(--raisin-black);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: white;
        color: var(--raisin-black);
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: white;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: white;
        padding: 14px 20px;
        border: 1px solid white;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: white;
        color: var(--raisin-black);
        transition: 250ms;
    }
}